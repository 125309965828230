import React, { useState, useContext, useEffect } from "react";
import Header from "./Header/Header";
import Calendar from "./Calendar/Calendar";
import BookingPanel from "./BookingPanel/BookingPanel";
import Footer from "./Footer/Footer";
import HotelInfo from "./HotelInfo/HotelInfo";
import FareRatesComponent from "./FareRates/FairRatesComponent";
import { animateScroll as scroll } from "react-scroll";
import {
  HotelIdContext,
  TempDateRangeContext,
  DateRangeContext,
  ReferenceFromContext,
  AdvanceBookingContext,
  ApiKeyContext,
} from "../settings/Store";
import userSignUp from "./BookingPanel/Cart/userSignUp";
import moment from "moment";
import Popups from "./Popups/Popups";
import config from "../config";
import { Snackbar } from "@material-ui/core";
import NotificationPopup from "./Popups/notificationPopup";
const BookingComponent = (props) => {
  const [tempDateRange, setTempDateRange] = useContext(TempDateRangeContext);
  const [dateRange, setDateRange] = useContext(DateRangeContext);
  const [userObject, setUserObject] = useState({});
  const [hotelId, setHotelId] = useContext(HotelIdContext);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [apiKey] = useContext(ApiKeyContext);
  const [hideHeaderNFooter, setHideHeaderNFooter] = useState(false);
  const queryString = props.location.search.replace("?q=", ""); // remove the "?", "&" and "="
  const [advanceDays, setAdvanceDays] = useContext(AdvanceBookingContext);
  const [calendarLoaded, setCalendarLoaded] = useState(false);
  const [showPriceCalendar, setShowPriceCalendar] = useState(1);
  // sessionStorage.setItem('gems', gems);
  // let data = queryString !== null && queryString !== "?gtm_debug=1685972493734" ? atob(decodeURI(queryString)) : "";
  let data = queryString !== null ? atob(decodeURI(queryString)) : "";
  // console.log('QueryData:' + queryString);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("gems"))) {
      const checkin = moment(JSON.parse(sessionStorage.getItem("checkin")));
      const checkout = moment(JSON.parse(sessionStorage.getItem("checkout")));
      // console.log("check in", checkin);
      // console.log("check out", checkout);
      // console.log("start date", tempDateRange.startDate)
      setDateRange({
        startDate: checkin,
        endDate: checkout,
      });
      setTempDateRange({
        startDate: checkin,
        endDate: checkout,
      });
      if (window.innerWidth > 766) {
        scroll.scrollTo(650);
      } else {
        scroll.scrollTo(970);
      }
    }
  }, []);

  useEffect(() => {
    // console.log('date range' + JSON.stringify(dateRange));
    // console.log('temp date range' + JSON.stringify(tempDateRange));
    // console.log('Advance days' + JSON.stringify(advanceDays));
    if (advanceDays) {
      if (JSON.parse(sessionStorage.getItem("gems"))) {
        const checkin = moment(JSON.parse(sessionStorage.getItem("checkin")));
        const checkout = moment(JSON.parse(sessionStorage.getItem("checkout")));
        // console.log("check in", checkin);
        // console.log("check out", checkout);
        // console.log("start date", tempDateRange.startDate)
        setDateRange({
          startDate: checkin,
          endDate: checkout,
        });
        setTempDateRange({
          startDate: checkin,
          endDate: checkout,
        });
        if (window.innerWidth > 766) {
          scroll.scrollTo(650);
        } else {
          scroll.scrollTo(970);
        }
      } else {
        setDateRange({
          startDate: moment().add(advanceDays, "days"),
          endDate: moment()
            .add(advanceDays, "days")
            .add(1, "days"),
        });
        setTempDateRange({
          startDate: moment().add(advanceDays, "days"),
          endDate: moment()
            .add(advanceDays, "days")
            .add(1, "days"),
        });
      }
    }
  }, [advanceDays]);
  // if (!JSON.parse(sessionStorage.getItem('page-refreshed'))) {
  //   window.location.reload(false);
  //   console.log('Hello in');
  //   sessionStorage.setItem('page-refreshed', true);
  // }

  useEffect(() => {
    fetch(
      config.apiEndpoint +
      "bookingEngine/get-hotel-info/" +
      apiKey +
      "/" +
      hotelId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        setAdvanceDays(responseJson.data.advance_booking_days);
        setShowPriceCalendar(responseJson.data.show_price_calendar);
      });
  }, [advanceDays]);

  useEffect(() => {
    if (data) {
      let queryStringData = data.split("|");


      let fromdate = moment();
      let todate = moment(fromdate).add(1, 'days');
      let from_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
      let to_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();


      if (!queryStringData[0]) {
        queryStringData[0] = from_date.toString();
      }
      if (!queryStringData[1]) {
        queryStringData[1] = to_date.toString();
      }


      // if (
      //   moment(new Date(parseInt(queryStringData[0]))).isBefore(
      //     moment().add(advanceDays, "days")
      //   )
      // ) {

      // } else {
      setDateRange({
        startDate: moment(new Date(parseInt(queryStringData[0]))),
        endDate: moment(new Date(parseInt(queryStringData[1]))),
      });

      setTempDateRange({
        startDate: moment(new Date(parseInt(queryStringData[0]))),
        endDate: moment(new Date(parseInt(queryStringData[1]))),
      });
      // }

      // setDateRange({
      //   startDate: moment().add(advanceDays, 'days'),
      //   endDate: moment()
      //     .add(advanceDays, 'days')
      //     .add(1, 'days')
      // });
      // setTempDateRange({
      //   startDate: moment().add(advanceDays, 'days'),
      //   endDate: moment()
      //     .add(advanceDays, 'days')
      //     .add(1, 'days')
      // });

      if (queryStringData.length > 3) {
        setUserObject({
          name: queryStringData[3].trim(),
          emailId: queryStringData[4].trim(),
          mobile: queryStringData[5].trim(),
        });
        setHotelId(parseInt(queryStringData[2].trim()));

        queryStringData[0] &&
          sessionStorage.setItem("checkin", queryStringData[0].trim());
        queryStringData[1] &&
          sessionStorage.setItem("checkout", queryStringData[1].trim());
        queryStringData[3] &&
          sessionStorage.setItem("name", queryStringData[3].trim());
        queryStringData[4] &&
          sessionStorage.setItem("email", queryStringData[4].trim());
        queryStringData[5] &&
          sessionStorage.setItem("phone", queryStringData[5].trim());

        queryStringData[6] && setHideHeaderNFooter(queryStringData[6].trim());

        queryStringData[7] &&
          sessionStorage.setItem("reference", queryStringData[7].trim());
        queryStringData[8] &&
          sessionStorage.setItem("source", queryStringData[8].trim());
      }
    }
  }, [data]);
  const setLocalToken = (token) => {
    //fake Call back to satisfy user signUp condition
    if (token && token !== "") {
      sessionStorage.setItem("token", token);
    }
  };
  //Api Call to User SignUp
  useEffect(() => {
    if (
      userObject.hasOwnProperty("name") &&
      userObject.hasOwnProperty("emailId") &&
      userObject.hasOwnProperty("mobile")
    ) {
      userSignUp(userObject, setToken, setLocalToken);
    }
  }, [userObject]);

  const [checkAvail, setCheckAvail] = useState(false);
  //Get CheckAvailability
  const getCheckAvail = (checkAvail) => {
    setCheckAvail(checkAvail);
  };
  const setNewCheckAvail = (checkAvail) => {
    setCheckAvail(checkAvail);
  };
  const [menuName, setMenuName] = useState(0); //Initial tab active
  //Get Menu Name Which clicked by user
  const getMenuName = (menu) => {
    setMenuName(menu);
  };
  const [bePrice, setBePrice] = useState(0);
  const callBackGetBePrice = (bePrice) => {
    setBePrice(bePrice);
  };

  useEffect(() => {
    if (showPriceCalendar === 0) {
      setCalendarLoaded(true)
    }
  }, [showPriceCalendar]);

  return (
    <div>
      <NotificationPopup />
      <Header
        history={props.history}
        hideHeaderNFooter={hideHeaderNFooter}
        callBackCheckAvail={getCheckAvail}
        callBackToBookingPanel={getMenuName}
      />
      {showPriceCalendar === 1 && (
        <Calendar
          callBackToSetCheckAvail={setNewCheckAvail}
          checkAvail={checkAvail}
          setCalendarLoaded={setCalendarLoaded}
          calendarLoaded={calendarLoaded}
        />
      )}

      <FareRatesComponent bePrice={bePrice} />
      {/* <Popups /> */}
      {showSnackbar && <Snackbar message="Not Available!" />}
      <BookingPanel
        callBackToSetCheckAvail={setNewCheckAvail}
        checkAvail={checkAvail}
        callBackGetBePrice={callBackGetBePrice}
        calendarLoaded={calendarLoaded}
      />
      {!hideHeaderNFooter && <HotelInfo menuIndex={menuName} />}
      {!hideHeaderNFooter && <Footer />}
    </div>
  );
};
export default BookingComponent;
