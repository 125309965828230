import React, { useState, useContext, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  HotelIdContext,
  ApiKeyContext,
  CompanyContext,
  AdvanceBookingContext,
} from "../../settings/Store";
import fetchHotelInfo from "./fetchHotelInfo";
import HotelAbout from "./hotelAbout.js";
import HotelAboutNew from "./hotelAboutNew.js";
import HotePolicies from "./hotelPolicies.js";
import HotelMap from "./hotelMap.js";
import HotelReviews from "./hotelReviews.js";
import HotelMapBox from "./HotelMapBox.js";

import AddonServices from './AddonServices';
const HotelInfo = (props) => {
  const [hotelId] = useContext(HotelIdContext);
  const [apiKey] = useContext(ApiKeyContext);
  const [hotelInfo, setHotelInfo] = useState([]);
  // const [advanceDays,setAdvanceDays]=useState(0);
  const [advanceDays, setAdvanceDays] = useContext(AdvanceBookingContext);

  const queryString = window.location.search.replace("?q=", "");
  let data = queryString !== null ? atob(decodeURI(queryString)) : "";
  // let data = queryString !== null && queryString !== "?gtm_debug=1685972493734" ? atob(decodeURI(queryString)) : "";


  useEffect(() => {

    if (hotelId && apiKey) {
      fetchHotelInfo(hotelId, apiKey, setHotelInfo);
    }
  }, [hotelId, apiKey, setHotelInfo]);

  useEffect(() => {
    setTabIndexes(parseInt(props.menuIndex));
  }, [props.menuIndex]);
  const [tabIndex, setTabIndex] = useState(1);
  //get new tabindex
  const setTabIndexes = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  return (
    <section>
      <div className="container container-width top-margin">
        <div className="row">
          <div className="col s12">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(tabIndex) => setTabIndexes(tabIndex)}
            >
              <div className="row">
                <div className="col s12">
                  <TabList className="tabs tab-section footer-tab">
                    <Tab className="tab col s2 full-width">
                      <a href="#tab-about" id="about">
                        About
                      </a>
                    </Tab>
                    <Tab className="tab col s2 full-width">
                      <a href="#tab-policies" id="policies">
                        Policies
                      </a>
                    </Tab>
                    <Tab className="tab col s2 full-width">
                      <a href="#tab-map" id="hotel-map">
                        Map
                      </a>
                    </Tab>

                    <Tab className="tab col s3 full-width">
                      <a href="#tab-addon-services" id="addon-services">
                        Add-on Services
                      </a>
                    </Tab>


                    {parseInt(hotelInfo.ota_hotel_code) > 0 && (
                      <Tab className="tab col s3 full-width">
                        <a href="#tab-reviews">Reviews</a>
                      </Tab>
                    )}
                  </TabList>
                </div>

                <div className="col s12 footer-margin-top">
                  <TabPanel>
                    {/* <HotelAbout hotelInfo={hotelInfo} /> */}
                    <HotelAboutNew hotelInfo={hotelInfo} />
                  </TabPanel>

                  <TabPanel>
                    <HotePolicies hotelInfo={hotelInfo} />
                  </TabPanel>

                  <TabPanel>
                    <HotelMap hotelInfo={hotelInfo} />
                  </TabPanel>
                  {/* <TabPanel>
                    <HotelMapBox hotelInfo={hotelInfo} />
                  </TabPanel> */}

                  <TabPanel>
                    <AddonServices hotelInfo={hotelInfo} />
                  </TabPanel>


                  {parseInt(hotelInfo.ota_hotel_code) > 0 && (
                    <TabPanel>
                      <HotelReviews
                        bookingDotComPropertyId={hotelInfo.ota_hotel_code}
                      />
                    </TabPanel>
                  )}
                </div>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
};
//Export HotelInfo
export default HotelInfo;
